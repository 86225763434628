// Step 1: Import React
import React, {useEffect, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Layout} from "../../components/Layout";
import DateSelect from "../../components/dateSelect/dateSelect";
import ProductBanner from "../../components/product_banners/product_banners";
import {InvestmentNews} from "../../components/investment_news/investmentNews";
import Banner from "../../imgs/bg_reportes.jpg";
import {navigate} from "gatsby";
import {apiRequest} from "../../apiRquest/apiRequest";
import "./reporte.css";
import "../articles.css";

const pageTitle = 'Prensa | Balanz';
const pageDesc = 'Mantenete informado con las últimas novedades y eventos del Mercado de Capitales. Todo lo que tenés que saber para invertir mejor.';


// Step 2: Define your component
const ReportPagePrensa = () => {
  const [date, setDate] = useState("prensa");
  const [prensaData, setPrensaData] = useState({});
  const [loadingPrensa, setLoadingPrensa] = useState(false);
  let options = [
      { name: "Daily", value: "daily", className: "report-option" },
      { name: "Weekly Fondos", value: "weekly-fondos",className: "report-option" },
      /*{ name: "Weekly Market", value: "weekly-market",className: "report-option" },
      { name: "Monthly", value: "monthly",className: "report-option" },*/
      { name: "Prensa", value: "prensa",className: "report-option" },
  ];

  let articulos_prensa = [
      {
          image_name: "/imgs/que-hacer-frente-al-ajuste-monteario-en-eeuu.jpg",
          title: "Para inversores: qué hacer frente al ciclo de ajuste monetario en EEUU más acelerado en 20 años",
          date: "Ámbito 21-03-2022",
          id: 1,
          link:'https://www.ambito.com/opiniones/inversores/para-que-hacer-frente-al-ciclo-ajuste-monetario-eeuu-mas-acelerado-20-anos-n5398287'
      },
      {
          image_name: "/imgs/ahorristas-3-alternativas-inversion-ganarle-inflacion.jpg",
          title: "Para pequeños ahorristas: 3 alternativas de inversión para ganarle a la inflación",
          date: "Ámbito 17-03-2022",
          id: 2,
          link:'https://www.ambito.com/finanzas/inversiones/para-pequenos-ahorristas-3-alternativas-inversion-ganarle-la-inflacion-n5395551'
      },
      {
          image_name: "/imgs/guia-entender-fed.jpg",
          title: "Guía para entender a la Fed: qué son el Tapering, el Tightening y el Quantitative Tightening",
          date: "Cronista 14-03-2022",
          id: 9,
          link:'https://www.cronista.com/finanzas-mercados/guia-para-entender-a-la-fed-que-es-el-tapering-el-tightening-y-el-quantitative-tightening'
      },
      {
          image_name: "/imgs/invertir-en-tiempos-de-guerra-y-fmi.jpg",
          title: "En qué invertir en tiempos de guerra y bajo el nuevo programa con el FMI",
          date: "Ámbito 09-03-2022",
          id: 10,
          link:'https://www.ambito.com/ambito-biz/ambito-biz/en-que-invertir-tiempos-guerra-y-el-nuevo-programa-el-fmi-n5388921'
      },

      {
          image_name: "/imgs/las-4-inversiones-que-se-pueden-hacer-hoy-en-pesos-y-ganar-en-dolares.jpg",
          title: "Las 4 inversiones que se pueden hacer hoy en pesos y ganar en dólares",
          date: "Clarín 15-12-2021",
          id: 1,
          link:'https://www.clarin.com/servicios/4-inversiones-pueden-hacer-hoy-pesos-ganar-dolares_0_63kF9q0Fx.html'
      },
      {
          image_name: "/imgs/cuales-son-los-cedears-que-pagan-dividendos-en-los-proximos-meses.jpg",
          title: "Cuáles son los Cedears que pagan dividendos en los próximos meses",
          date: "Ámbito 04-12-2021",
          id: 2,
          link:'https://www.ambito.com/opiniones/cedears/cuales-son-los-que-pagan-dividendos-los-proximos-meses-n5329455'
      },
      {
          image_name: "/imgs/aguinaldo-de-diciembre-en-que-invertir-el-sueldo-anual-complementario-si-sobran-algunos-pesos.jpg",
          title: "Aguinaldo de diciembre: en qué invertir el sueldo anual complementario si sobran algunos pesos",
          date: "TN 04-12-2021",
          id: 3,
          link:'https://tn.com.ar/economia/2021/12/04/aguinaldo-en-diciembre-en-que-invertir-el-sueldo-anual-complementario-si-sobran-algunos-pesos/'
      },
      {
          image_name: "/imgs/tapering-ahora-la-atencion-se-centra-en-la-velocidad-del-desarme.jpg",
          title: "Tapering: ahora la atención se centra en la velocidad del desarme",
          date: "Cronista 03-12-2021",
          id: 4,
          link:'https://www.cronista.com/finanzas-mercados/tapering-el-mercado-deja-de-verlo-como-un-evento-y-ahora-la-atencion-se-centra-en-la-velocidad-del-desarme/'
      },
      {
          image_name: "/imgs/aguinaldo-en-que-conviene-invertir-para-no-perder-contra-la-inflacion.jpg",
          title: "Aguinaldo: ¿En qué conviene invertir para no perder contra la inflación?",
          date: "Ámbito 28-11-2021",
          id: 5,
          link:'https://www.ambito.com/finanzas/invertir/aguinaldo-en-que-conviene-no-perder-contra-la-inflacion-n5325663'
      },
      {
          image_name: "/imgs/dolar-ahorro-o-inversion.jpg",
          title: "Dólar ¿Ahorro o inversión?",
          date: "Ámbito 03-10-2021",
          id: 6,
          link:'https://www.ambito.com/opiniones/ahorro/dolar-o-inversion-n5290862'
      },

    ];

    useEffect(() => {
        setLoadingPrensa(true);

            apiRequest
                //.get(`reports/reportes.json`)
                .get("/v1/prensa")
                .then((res) => {
                    let dataSet = [];
                    let fullData = res.data.data;
                    if (fullData.length > 0){
                        fullData.forEach((item) => {
                            dataSet.push({
                                imgUrl: item.image_name,
                                date: item.date,
                                link: item.link,
                                title: item.title,
                                order: item.order
                            });
                        });
                        setPrensaData(dataSet)

                    }
                })
                .catch((err) => {
                    console.log(err);
                }).finally(()=>{
                setLoadingPrensa(false);
            });

    }, []);

    useEffect(() => {
        const handleChange = () => {
            navigate(`/reportes/${ date }`);
        };

        if(date != "prensa"){
            handleChange();
        }

    }, [date]);

  const page = (
      <>
          <ProductBanner
              bannerContainerClassName="bannerInversiones"
              bannerTrasparecy="bannerMask"
              productTitle="Reportes"
              bannerImage={Banner}
              bannerImageClassName="ReportBannerImage"
              textContainerClassName="textContainer"
              bannerText={false}
              bannerTextClassName="bannerTextInversiones"
              bannerImageMobile={Banner}
              mobile={false}
          />
          <section className="fullwidth">
              <Container>
                  <Row className="pb-5">
                      <DateSelect
                          options={options}
                          selectName={"report-select"}
                          controller={setDate}
                          className="report-select"
                          selected={'prensa'}
                      />
                  </Row>
              </Container>

              <div className="report-abstract bg-lt-grey">
                  <Container>
                      <Row className="py-4">
                          <Col xs={12} md={9} className="d-flex align-items-center" >
                              <p className="base-text m-0 py-4">
                                  Relevamiento y clipping de las notas de prensa más relevantes sobre Balanz.
                              </p>
                          </Col>
                      </Row>
                  </Container>
              </div>
              <br /><br />
              <Container>
                  <Row>
                      <Col xs={12}>
                          <InvestmentNews
                              containerClass="university-articles report-articles row rowlist"
                              cardHolderClass= "col-12 col-md-6"
                              investmentNews={prensaData} />
                      </Col>
                  </Row>
              </Container>

          </section>

      </>
  );

  return (
    <main>
        <Layout title={pageTitle} description={pageDesc} childrem={page} category={"reportes-prensa"}></Layout>
    </main>
  );
};

// Step 3: Export your component
export default ReportPagePrensa;
